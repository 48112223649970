.optin__container {
  width: 100vw;
  background-color: #2596be;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  margin-left: -110px;
}

.optin__inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 15px;
  margin-left: 110px;
  max-width: 1080px;
}

.optin__title {
  margin-bottom: 0.5rem;
}

.optin__caption {
  max-width: 700px;
  text-align: center;
  margin-bottom: 3.75rem;
}

.optin__card-container {
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1.875rem;
}

.optin__card {
  text-align: center;
  padding: 1.875rem;
  border-radius: var(--border-radius);
  background-color: var(--container-color);
  box-shadow: var(--shadow);
  transition: all 0.3s ease-in-out;
}

.optin__card:hover {
  transform: translateY(-10px);
}

.optin__card-title {
  font-size: var(--h3-font-size);
  text-align: center;
  margin-bottom: 1.25rem;
}

.optin__card-price {
  font-size: var(--h1-font-size);
  font-weight: var(--font-bold);
  margin-top: 3.75rem;
}

.optin__card-price em {
  font-style: normal;
  font-size: var(--small-font-size);
  margin-right: 0.5rem;
  vertical-align: super;
}

.optin__card-price span {
  font-size: var(--normal-font-size);
  font-weight: 400;
  margin-left: 0.75rem;
}

@media screen and (max-width: 1024px) {
  .optin__container {
    margin-left: 0;
  }

  .optin__inner-container {
    margin-left: 0;
    width: 100%;
  }

  .optin__card-container {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 1.875rem;
    width: 100%;
  }

  .optin__zh-title {
    font-size: 1.25rem !important;
  }
}