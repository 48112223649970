.home {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.intro {
  max-width: 540px;
  text-align: center;
}

.home__img {
  margin-bottom: 1.5rem;
}

.home__name {
  font-size: var(--h1-font-size);
  font-weight: var(--font-bold);
  margin-bottom: 0.5rem;
}

.home__socials {
  display: flex;
  justify-content: center;
  column-gap: 1.75rem;
  margin: 1.5rem 0;
}

.home__social-link {
  color: var(--title-color);
  font-size: 1.3rem;
  transition: 0.3s;
}

.home__social-link:hover {
  color: hsl(353, 100%, 65%);
}

.scroll__down {
  position: absolute;
  bottom: 2.5rem;
  left: 0;
  width: 100%;
}

.home__scroll-name {
  color: #454360;
  font-size: var(--small-font-size);
}

.mouse {
  border: 2px solid #454360;
  display: block;
  height: 1.6rem;
  width: 1.25rem;
  margin: auto;
  margin-top: 0.75rem;
  border-radius: 1rem;
  position: relative;
}

@keyframes ani-mouse {
  0% {
    top: 29%;
  }

  15% {
    top: 50%;
  }

  50% {
    top: 50%;
  }

  100% {
    top: 29%;
  }
}

.wheel {
  background-color: var(--title-color);
  border-radius: 100%;
  width: 0.25rem;
  height: 0.25rem;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  animation: ani-mouse 2s linear infinite;
}