.advisors__container {
  max-width: 700px;
  margin: auto;
  z-index: -100;
}

.advisors__item {
  text-align: center;
  padding: 0 1.25rem 1.25rem;
}

.advisors__title {
  margin-top: 0.75rem;
}