.aside {
  position: fixed;
  left: 0;
  top: 0;
  background: var(--body-color);
  border-right: 1px solid rgb(0 0 0 / 5%);
  padding: 2.5rem;
  width: 110px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 200;
  transition: 0.3s;
}

.nav__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
}

.nav__link,
.nav__toggle {
  font-size: 1.5rem;
  color: var(--title-color);
  font-weight: var(--font-bold);
  transition: 0.3s;
  cursor: pointer;
}

.nav__link:hover {
  color: hsl(353, 100%, 65%);
}

.copyright {
  color: hsl(245, 15%, 65%);
  font-size: var(--small-font-size);
  display: block;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  white-space: nowrap;
}

.nav__toggle {
  position: fixed;
  top: 1.25rem;
  left: 1.875rem;
  cursor: pointer;
  height: 40px;
  width: 45px;
  border: 1px solid #e8dfec;
  background-color: var(--body-color);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transition: 0.3s;
}

@media screen and (max-width: 1024px) {
  .aside {
    left: -110px;
  }

  .nav__toggle {
    display: flex;
  }

  .nav__toggle-open {
    left: 140px;
  }

  .show-menu {
    left: 0;
  }
}