.banner__container {
  width: 100vw;
  background-color: #e5bc52;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}

.banner__inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 110px;
  padding: 2.5rem;
}

.banner__title {
  margin-bottom: 0.5rem;
}

.banner__caption {
  max-width: 700px;
  text-align: center;
  margin-bottom: 1.25rem;
}

@media screen and (max-width: 1024px) {
  .banner__inner-container {
    margin-left: 0;
  }
}