.about__subtitle {
  margin-bottom: 3.75rem;
  max-width: 700px;
}

.about__container {
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1.875rem;
}

.about__card {
  text-align: center;
  padding: 1.875rem;
  border-radius: var(--border-radius);
  background-color: var(--container-color);
  box-shadow: var(--shadow);
  transition: all 0.3s ease-in-out;
}

.about__card:hover {
  transform: translateY(-10px);
}

.about__title {
  font-size: var(--h3-font-size);
  text-align: center;
  margin-bottom: 1.25rem;
}

@media screen and (max-width: 1024px) {
  .about__container {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 1.875rem;
  }

  .about__zh-title {
    font-size: 1.25rem !important;
  }
}